import React, { useState, useEffect } from "react"
import { Link } from 'gatsby'
import { Layout, SEO } from '../../components/common'
import { navigate } from "gatsby"

import Notebook from '../../components/notebooks/compare'
import LicenseCode from '../../components/licensecode'

import '../../styles/vis.css'

// import Notebook from '../../components/notebooks/compare'
// <Notebook />

const VisComparePage = ({ data, location, pageContext }) => {
    const [licenseKey, setLicenseKey] = useState('demo');  // TODO: remove demo later
    const [validated, setValidated] = useState(true);  // TODO when implementing license codes

    // get the license key from the url
    // watch out the "navigate", fails prod build
    // if (!licenseKey){
    //     if (!location.pathname.includes('vis/compare/')){
    //         // this really shouldn't happen. but take user to product page if it does
    //         navigate("/exploration/compare/?error=invalidurl")
    //         return ''
    //     }
    //     // cut off beginning of url, and get rid of any ending backslash or query params
    //     const urlkey = location.pathname.split('vis/compare/')[1].split('/')[0];
    //     if (!urlkey){
    //         navigate("/exploration/compare/?error=nokey")
    //         return ''
    //     } else {
    //         setLicenseKey(urlkey)
    //     }
    // }

    // validate license key when it's set
    useEffect(() => {
        console.log("go validate the license key")
    }, [licenseKey]);
    // eslint-disable-line react-hooks/exhaustive-deps

    // actions
    const submitLicenseCode = (code) => {
        console.log("submitting code", code, licenseKey)
    }

    return (
        <Layout>
            <SEO title={`Compare: ${licenseKey}`} description={'This analysis allows for you to upload several types of quantified self data, visualize any individual metric, and look for correlations between that metric and others.'}/>
            <div className="container">
                <article className="content">
                    <section className="post-full-content">
                        {validated ? (
                            <React.Fragment>
                                <section id="embed-target" className="content-body">
                                    <Notebook />
                                </section>
                            </React.Fragment>
                        ) : (
                            <LicenseCode submit={submitLicenseCode}/>
                        )}
                    </section>
                </article>
            </div>
        </Layout>
    )
}

export default VisComparePage

// export const pageQuery = graphql`
//     query LicenseKeyQuery {
//         airtable(
//             table: { eq: "Orders" }
//             data: { License_Key: { eq: "fedfed" } }
//         ) {
//             data {
//                 Code
//                 Email
//                 Status
//             }
//         }
//     }
// `