import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const LicenseCode = ({ submit }) => {
    
    return (
        <div>
            <h2>Passcode Required</h2>
            <form onSubmit={(e) => {
                e.preventDefault();
                const code = document.getElementById('passcode').value;
                if (code){
                    submit(code)
                }
            }}>
                <input id="passcode" type="text" placeholder="Enter Passcode"></input>
                <input type="Submit" title="Submit"/>
            </form>
        </div>
    )
}

export default LicenseCode
