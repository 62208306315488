import React, {useRef, useEffect} from "react";
import {Runtime, Inspector} from "@observablehq/runtime";
import notebook from "b3a06aa5487f9b6f";

function CompareNotebook() {
  const ref = useRef();

  useEffect(() => {
    (new Runtime).module(notebook, name => {
      if (name === "title") return Inspector.into(ref.current.querySelector(".title"))();
      if (name === "intro") return Inspector.into(ref.current.querySelector(".intro"))();
      if (name === "fileuploaddescription") return Inspector.into(ref.current.querySelector(".fileuploaddescription"))();
      if (name === "viewof uploadedfile") return Inspector.into(ref.current.querySelector(".viewof-uploadedfile"))();
      if (name === "uploadedmetrics") return Inspector.into(ref.current.querySelector(".uploadedmetrics"))();
      if (name === "uploadedtable") return Inspector.into(ref.current.querySelector(".uploadedtable"))();
      if (name === "letsdivein") return Inspector.into(ref.current.querySelector(".letsdivein"))();
      if (name === "viewof currentMetricName") return Inspector.into(ref.current.querySelector(".viewof-currentMetricName"))();
      if (name === "filterinfo") return Inspector.into(ref.current.querySelector(".filterinfo"))();
      if (name === "viewof timescale") return Inspector.into(ref.current.querySelector(".viewof-timescale"))();
      if (name === "viewof aggtype") return Inspector.into(ref.current.querySelector(".viewof-aggtype"))();
      if (name === "viewof timerange") return Inspector.into(ref.current.querySelector(".viewof-timerange"))();
      if (name === "statstable") return Inspector.into(ref.current.querySelector(".statstable"))();
      if (name === "viewof lineChart") return Inspector.into(ref.current.querySelector(".viewof-lineChart"))();
      if (name === "viewof variousCharts") return Inspector.into(ref.current.querySelector(".viewof-variousCharts"))();
      if (name === "prettywild") return Inspector.into(ref.current.querySelector(".prettywild"))();
      if (name === "apeekaround") return Inspector.into(ref.current.querySelector(".apeekaround"))();
      if (name === "viewof sensitivity") return Inspector.into(ref.current.querySelector(".viewof-sensitivity"))();
      if (name === "foundcomparisons") return Inspector.into(ref.current.querySelector(".foundcomparisons"))();
      if (name === "correlationstable") return Inspector.into(ref.current.querySelector(".correlationstable"))();
      if (name === "correlationschart") return Inspector.into(ref.current.querySelector(".correlationschart"))();
      if (name === "prettyneat") return Inspector.into(ref.current.querySelector(".prettyneat"))();
      if (name === "crunchtime") return Inspector.into(ref.current.querySelector(".crunchtime"))();
      if (name === "viewof significance") return Inspector.into(ref.current.querySelector(".viewof-significance"))();
      if (name === "allcorrelationstable") return Inspector.into(ref.current.querySelector(".allcorrelationstable"))();
      if (name === "allcorrelationscharts") return Inspector.into(ref.current.querySelector(".allcorrelationscharts"))();
      if (name === "theend") return Inspector.into(ref.current.querySelector(".theend"))();
      return ["uploadeddata","dailydata","allMetrics","bestTimeFrame","usableMetrics","timescale","sensitivity","currentMetricName","currentMetric","getComparisons","allCorrelations","getChunks","timerange","lineChart","comparisons","daily_measurements","chartChunks","variousCharts","topCorrelationsAofA","bucketedTopCorrelations","metric_chart_data","topCorrelations","regressionResults","topCorrelationsToShow","topCorrelationsRThreshold"].includes(name);
    });
  }, []);

  return (
    <div className="Notebook" ref={ref}>
      <div className="title"></div>
      <div className="intro"></div>
      <div className="fileuploaddescription"></div>
      <div className="viewof-uploadedfile"></div>
      <div className="uploadedmetrics"></div>
      <div className="uploadedtable"></div>
      <div className="letsdivein"></div>
      <div className="viewof-currentMetricName"></div>
      <div className="filterinfo"></div>
      <div className="viewof-timescale"></div>
      <div className="viewof-aggtype"></div>
      <div className="viewof-timerange"></div>
      <div className="statstable"></div>
      <div className="viewof-lineChart"></div>
      <div className="viewof-variousCharts"></div>
      <div className="prettywild"></div>
      <div className="apeekaround"></div>
      <div className="viewof-sensitivity"></div>
      <div className="foundcomparisons"></div>
      <div className="correlationstable"></div>
      <div className="correlationschart"></div>
      <div className="prettyneat"></div>
      <div className="crunchtime"></div>
      <div className="viewof-significance"></div>
      <div className="allcorrelationstable"></div>
      <div className="allcorrelationscharts"></div>
      <div className="theend"></div>
    </div>
  );
}

export default CompareNotebook;
